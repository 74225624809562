.rows,
.columns {
  display: flex;
  align-items: stretch;

  &.flex-gap {
    gap: 1rem;
  }

  > *.flex-center {
    display: flex;
    align-items: center;
  }
}

.rows {
  flex-direction: column;

  .row {

  }
}

.columns {
  flex-direction: row;
  .column {

  }
}
