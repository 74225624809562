@import "variables";


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@mixin fadeIn() {
  animation-name: fadeIn;
  animation-duration: $base-animation-period;
}
.fadeIn {
  @include fadeIn();
}
