// IMPORTS
@import "assets/styles/variables";
@import "assets/styles/animations";
@import "assets/styles/forms";
@import "assets/styles/alerts";
@import "assets/styles/layout";

// FONTS
@import "assets/fonts/Nucleo-icons/fonts/nucleo-glyph.css";


// RESETS
* {
  box-sizing: border-box;
}

@font-face {
  src: local('Open Sans Regular'),
  url('assets/fonts/opensans/open-sans.woff') format('woff'),
  url('assets/fonts/opensans/open-sans.woff2') format('woff2');
}

html {
  font-size: 10px;
}

body {
  font-size: 1.4rem;
  font-family: 'Open Sans Regular', Arial, sans-serif;
  color: $color-text-regular;
}

html,
body,
app-root {
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

section,
.container {
  max-width: 1366px;
}

section {
  @include padding(4, 8);
  @include fadeIn();
  max-width: 1366px;
  margin: 0 auto;
  &:last-child {
    @include padding(4, 8, 8, 8);
  }
}

header,
footer {
  flex: 0 0 auto;
  .container {
    @include padding(1, 5);
    max-width: 1366px;
    margin: 0 auto;
  }
}

main {
  flex: 1 0 auto;
  overflow: auto;
}


h1,
h2,
h3,
h4,
h5 {
  color: $color-text-dark;
  &.light {
    font-weight: normal;
  }
}

h1 {
  font-size: 3.6rem;
}

h2 {
  font-size: 3.0rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 1.4rem;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.small {
  font-size: 0.8em;
}
.smaller {
  font-size: 0.6em;
}
