$color-midnight-blue: #003657;
$color-broadridge-blue: #00578e;
$color-cyan-overlap: #007bb6;
$color-cyan-secondary: #00a3d6;
$color-black: #333333;
$color-text-dark: #3c3c3c;
$color-text-regular: #6d6d6d;
$color-gray-dark: #999999;
$color-gray-medium: #cccccc;
$color-gray-light: #dddddd;
$color-gray-extra-light: #f9f9f9;
$color-white: #ffffff;
$color-green-overlap: #4f8636;
$color-text-green: #96a100;
$color-green: #a8b400;
$color-purple: #5255a5;
$color-orange: #ee7624;
$color-validation-error-red: #cc2338;
$color-secondary-button-hover: #aaaaaa;

$color-alert-info: #003657;
$color-alert-success: #348347;
$color-alert-warning: #EE7624;
$color-alert-danger: #CC2330;


// TIMING
$base-interactive-period: 0.3s;
$base-animation-period: 0.8s;


// SPACING
$max-page-width: 1366px;
$base-spacing-unit: 8px;

$margin-base: $base-spacing-unit;
@mixin margin($top-margin-multiple: false, $right-margin-multiple: false, $bottom-margin-multiple: false, $left-margin-multiple: false) {
  @if ($left-margin-multiple != false) {
    margin: ($top-margin-multiple * $margin-base) ($right-margin-multiple * $margin-base) ($bottom-margin-multiple * $margin-base) ($left-margin-multiple * $margin-base);
  } @else if ($bottom-margin-multiple != false) {
    margin: ($top-margin-multiple * $margin-base) ($right-margin-multiple * $margin-base) ($bottom-margin-multiple * $margin-base);
  } @else if ($right-margin-multiple != false) {
    margin: ($top-margin-multiple * $margin-base) ($right-margin-multiple * $margin-base);
  } @else if ($top-margin-multiple != false) {
    margin: ($top-margin-multiple * $margin-base);
  }
}

$padding-base: $base-spacing-unit;
@mixin padding($top-padding-multiple: false, $right-padding-multiple: false, $bottom-padding-multiple: false, $left-padding-multiple: false) {
  @if ($left-padding-multiple != false) {
    padding: ($top-padding-multiple * $padding-base) ($right-padding-multiple * $padding-base) ($bottom-padding-multiple * $padding-base) ($left-padding-multiple * $padding-base);
  } @else if ($bottom-padding-multiple != false) {
    padding: ($top-padding-multiple * $padding-base) ($right-padding-multiple * $padding-base) ($bottom-padding-multiple * $padding-base);
  } @else if ($right-padding-multiple != false) {
    padding: ($top-padding-multiple * $padding-base) ($right-padding-multiple * $padding-base);
  } @else if ($top-padding-multiple != false) {
    padding: ($top-padding-multiple * $padding-base);
  }
}
