@import "variables";

fieldset {
  border: 1px solid $color-broadridge-blue;
  border-radius: 4px;
  display: inline-block;
  width: auto;
  padding: 4px;
  @include margin(0,0,0.6,0);

  &.disabled {
    border-color: $color-gray-medium;

    legend {
      color: $color-gray-medium;
    }
  }

  legend {
    font-size: 1.2rem;
    color: $color-broadridge-blue;
  }

  input {
    display: inline-block;
    border: none;
    outline: none;
  }
}

button {
  background-color: $color-cyan-overlap;
  color: $color-white;
  @include padding(1);
  border: none;
  border-radius: 4px;
  transition: all ease-in-out $base-interactive-period;
  cursor: pointer;

  &:hover {
    background-color: $color-midnight-blue;
  }

  &[disabled] {
    background-color: $color-gray-light;
    color: $color-gray-dark;
  }

  &.error {
    background-color: $color-validation-error-red;
    color: $color-white;
  }

  &.success {
    background-color: $color-green-overlap;
    color: $color-white;
  }

}

