@import "variables";

.alert {
  @mixin setAlertBG($bg-color) {
    color: $color-white;
    background-color: $bg-color;

  }


  &.info {
    @include setAlertBG($color-alert-info);
  }

  &.success {
    @include setAlertBG($color-alert-success);
  }

  &.warning {
    @include setAlertBG($color-alert-warning);
  }

  &.danger {
    @include setAlertBG($color-alert-danger);
  }
}
p.alert {
  &.info,
  &.success,
  &.warning,
  &.danger {
    @include padding(1, 2);
    border-radius: 4px;
    span.icon {
      margin-right: 8px;
    }
  }
}
